<template>
  <div class="main-container">
    <v-overlay v-if="loading || !businessStore" class="loading-center">
      <Lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-container
      fluid
      v-if="businessStore && !loading"
      class="screen-width pt-0 pt-md-4"
    >
      <v-row>
        <v-col class="pa-0">
          <img
            :src="businessStore.cover.original"
            :lazy-src="businessStore.cover.loading"
            class="grey lighten-2 cover-up rounded"
          />
          <v-img
            id="logo"
            :src="businessStore.logo.original"
            :lazy-src="businessStore.logo.loading"
            aspect-ratio="1"
            class="grey lighten-2 logo-up"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            id="businessName"
            class="text-h4 text-md-h3 mt-n0 mt-md-n8 text-center text--primary"
          >
            {{ businessStore.shortName }}
          </div>
        </v-col>
      </v-row>
      <ProductLoop :query="$store.state.search" :limit="limit" />
    </v-container>
  </div>
</template>

<script>
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import ProductLoop from "@/components/ProductLoop.vue";
const fb = require("@/firebase.js");
import { mapState } from "vuex";
// http://localhost:8080/business/la-colonia-nuevo/d49bpu9f4
// http://localhost:8080/business/la-tomatina/d4d00p0gc
// http://localhost:8080/business/little-caesars/d43zzr81b
// http://localhost:8080/business/palacio-real/d49bpvhtb

export default {
  name: "Business",
  components: {
    Lottie,
    ProductLoop,
  },
  mixins: [lottieSettings],
  data() {
    return {
      webName: this.$route.params.webName,
      geoHash: this.$route.params.geoHash,
      loading: true,
      tempId: "",
      limit: 15,
    };
  },
  created() {
    this.checkSlug();
  },
  async mounted() {
    if (this.geoHash.length > 0) {
      await this.getBusiness();
      await this.getMenu();
      await this.getProducts();
      await this.getAddons();
      this.loading = false;
      document.title = `Ocho | ${this.businessStore.shortName}`;
      this.$store.state.visibleSearch = true;
      this.$store.state.search = "";
    }
  },
  methods: {
    async getBusiness() {
      if (this.businessStore == null) {
        await fb.businessCollection
          .where("webName", "==", this.webName)
          .where("geoAddress.geohash", "==", this.geoHash)
          .limit(1)
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length == 1) {
              snapshot.docs.forEach((doc) => {
                this.tempId = doc.id;
                this.$store.commit(
                  "setBusiness",
                  Object.assign({ id: doc.id }, doc.data())
                );
              });
            } else {
              this.$router.push({ name: "Home", params: { error: true } });
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
            this.$router.push({ name: "Home", params: { error: true } });
          });
      }
    },
    async getMenu() {
      if (this.menuStore.length == 0) {
        let menuArr = [];
        await fb.db
          .collection(`businesses/${this.tempId}/menu`)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .get()
          .then((snapshot) => {
            snapshot.docs.map((doc) => {
              menuArr.push(Object.assign({ id: doc.id }, doc.data()));
            });
            this.$store.commit("setMenu", menuArr);
          })
          .catch((error) => {
            this.$store.commit("setMenu", []);
            console.log("Error getting document:", error);
          });
      }
    },
    getProducts() {
      if (this.productsStore.length == 0) {
        let productArr = [];
        this.menuStore.forEach((item) => {
          fb.db
            .collection("products")
            .where("menu", "array-contains", `${item.id}`)
            .get()
            .then((snapshot) => {
              snapshot.docs.map((doc) => {
                productArr.push(Object.assign({ id: doc.id }, doc.data()));
              });
              this.$store.commit("setProducts", productArr);
            })
            .catch((error) => {
              this.$store.commit("setProducts", []);
              console.log("Error getting document:", error);
            });
        });
      }
    },
    checkSlug() {
      if (this.geoHash.length <= 0 || this.webName.length <= 0) {
        this.$router.push({ name: "Home", params: { error: true } });
      }
    },
    async getAddons() {
      if (this.addOnsStore.length == 0) {
        let addOnsArray = [];
        await fb.db
          .collection("addons")
          .where("businessId", "==", `${this.businessStore.id}`)
          .where("active", "==", true)
          .where("deleted", "==", false)
          .get()
          .then((snapshot) => {
            snapshot.docs.map((doc) => {
              addOnsArray.push(Object.assign({ id: doc.id }, doc.data()));
            });
            this.$store.commit("setAddons", addOnsArray);
          })
          .catch((error) => {
            this.$store.commit("setAddons", []);
            console.log("Error getting document:", error);
          });
      }
    },
  },
  computed: {
    ...mapState(["productsStore", "businessStore", "menuStore", "addOnsStore"]),
  },
};
</script>

<style scoped>
.logo-up {
  background-color: rgba(255, 255, 255, 0.643);
  border: 1px solid gray;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  margin-top: -90px;
  margin-left: 20px;
  transition: all 0.3s ease-out;
  max-width: 130px;
  max-height: 130px;
}
.logo-up:hover {
  border: 2px dashed #f06f3d;
}
@media only screen and (max-width: 600px) {
  .logo-up {
    max-width: 80px;
    max-height: 80px;
    margin-top: -65px;
  }
}
.cover-up {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}
.cover-up:hover {
  border: 2px dashed #f06f3d;
}
@media only screen and (max-width: 600px) {
  .cover-up {
    max-height: 150px;
  }
}
</style>
